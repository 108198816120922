<template>
	<div>
		<div v-if="!loading" class="tabs">
			<div class="">
				<ul role="tablist" class="nav nav-pills justify-content-end tabs-nav">
					<div class="mr-auto">
						<h2>{{ id }} - {{ getProjectType(projectData.type) }}</h2>
					</div>
					<li role="presentation" class="nav-item">
						<router-link exact :to="{ name: 'project.cfw.details' }" class="nav-link">Sub Projects</router-link>
					</li>
					<li role="presentation" class="nav-item">
						<router-link :to="{ name: 'project.cfw.stores' }" class="nav-link">Project Stores</router-link>
					</li>
					<li role="presentation" class="nav-item">
						<router-link :to="{ name: 'project.cfw.able-to-work' }" class="nav-link">Able To Work</router-link>
					</li>
					<li role="presentation" class="nav-item">
						<router-link :to="{ name: 'project.cfw.staff' }" class="nav-link">Staff</router-link>
					</li>
					<li role="presentation" class="nav-item">
						<router-link :to="{ name: 'project.cfw.plans' }" class="nav-link">Purchase Plans</router-link>
					</li>
					<li role="presentation" class="nav-item">
						<router-link :to="{ name: 'project.cfw.reports' }" class="nav-link">Reports</router-link>
					</li>
					<li role="presentation" class="nav-item d-flex align-items-center ml-50">
						<settings :data="projectData.setup" />
					</li>
				</ul>
			</div>
			<div class="tab-content pt-1">
				<router-view></router-view>
			</div>
		</div>
	</div>
</template>

<script>
import store from '@/store';
import { getProjectType } from '@/utils/helpers';
import Settings from './settings.vue';

export default {
	name: 'CashForWorkIndex',

	components: { Settings },

	props: {
		id: {
			type: [String, Number],
			required: true,
		},
	},

	data: () => ({
		projectData: null,
		loading: false,
	}),

	async created() {
		this.loading = true;
		try {
			this.projectData = await store.dispatch('projects/getProject', this.$route.params.id);
		} catch (error) {
			console.error(error);
		}

		this.loading = false;
	},

	methods: {
		getProjectType,
	},
};
</script>

<style lang="scss" scoped>
.nav-pills .router-link-active {
	border-color: #8bc53f;
	background-color: #8bc53f;
	box-shadow: 0 4px 18px -4px rgb(139 197 63 / 65%);
	color: #fff;
}
</style>
