<template>
	<div>
		<b-button
			v-b-modal.new-setup-settings-modal
			v-b-tooltip.hover.top="'Setup Settings'"
			variant="outline-secondary"
			class="btn-icon cursor-pointer"
			size="sm"
			@click="key++"
		>
			<feather-icon icon="SettingsIcon" size="16" />
		</b-button>
		<b-modal id="new-setup-settings-modal" ref="newSetupSettingsModal" title="Project setup settings" centered @hidden="resetModal">
			<validation-observer ref="formRules">
				<b-form ref="form">
					<b-form-group label="City officer" label-for="cityOfficer">
						<validation-provider #default="{ errors }" name="City officer" rules="required">
							<v-select
								id="cityOfficer"
								v-model="formData.city_officer_id"
								label="name"
								:filterable="false"
								:searchable="false"
								:options="cityOfficers"
								:reduce="(o) => o.id"
								:state="errors.length > 0 ? false : null"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>

					<b-form-group label="Consultant" label-for="consultant">
						<validation-provider #default="{ errors }" name="Consultant" rules="required">
							<v-select
								id="consultant"
								v-model="formData.consultant_id"
								label="name"
								:filterable="false"
								:searchable="false"
								:options="consultants"
								:reduce="(o) => o.id"
								:state="errors.length > 0 ? false : null"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>

					<b-form-group label="Accountant" label-for="accountant">
						<validation-provider #default="{ errors }" name="Accountant" rules="required">
							<v-select
								id="accountant"
								v-model="formData.accountant_id"
								label="name"
								:filterable="false"
								:searchable="false"
								:options="accountants"
								:reduce="(o) => o.id"
								:state="errors.length > 0 ? false : null"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>

					<b-form-group label="Store keeper" label-for="storeKeeper">
						<validation-provider #default="{ errors }" name="Store keeper" rules="required">
							<v-select
								id="storeKeeper"
								v-model="formData.store_keeper_id"
								label="name"
								:filterable="false"
								:searchable="false"
								:options="storeKeepers"
								:reduce="(o) => o.id"
								:state="errors.length > 0 ? false : null"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>
					<b-form-group label="Bank account number" label-for="banc-number">
						<validation-provider #default="{ errors }" name="Bank account number" rules="required">
							<b-form-input
								id="banc-number"
								v-model="formData.bank_account_id"
								type="number"
								:state="errors.length > 0 ? false : null"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>
				</b-form>
			</validation-observer>

			<template #modal-footer="{ cancel }">
				<b-button variant="outline-secondary" @click="cancel">Cancel</b-button>
				<loading-btn variant="primary" :loading="loading" @click="handleOk">Save</loading-btn>
			</template>
		</b-modal>
	</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { BFormInput, BFormGroup, BForm, BButton, VBTooltip, VBModal } from 'bootstrap-vue';
import { required } from '@validations';
import vSelect from 'vue-select';
import LoadingBtn from '@/components/LoadingBtn.vue';
import store from '@/store';
import api from '@/utils/api';

export default {
	name: 'SetupSettings',

	components: {
		ValidationProvider,
		ValidationObserver,
		BFormInput,
		BFormGroup,
		BForm,
		BButton,
		LoadingBtn,

		vSelect
	},

	directives: {
		'b-modal': VBModal,
		'b-tooltip': VBTooltip
	},

	props: {
		data: {
			type: Object,
			default: () => ({
				city_officer_id: '',
				consultant_id: '',
				accountant_id: '',
				store_keeper_id: '',
				bank_account_id: ''
			})
		}
	},

	data: () => ({
		key: 0,
		required,

		cityOfficers: [],
		consultants: [],
		accountants: [],
		storeKeepers: [],

		loading: false
	}),

	computed: {
		formData() {
			return this.data;
		}
	},

	async created() {
		this.cityOfficers = (await store.dispatch('users/getUsers', { type: 15 })).data;
		this.consultants = (await store.dispatch('users/getUsers', { type: 16 })).data;
		this.accountants = (await store.dispatch('users/getUsers', { type: 13 })).data;
		this.storeKeepers = (await store.dispatch('users/getUsers', { type: 14 })).data;
	},

	methods: {
		resetModal() {
			this.$emit('hidden');
		},

		handleOk(bvModalEvt) {
			// Prevent modal from closing
			bvModalEvt.preventDefault();
			// Trigger submit handler
			this.handleSubmit();
		},

		async handleSubmit() {
			const success = await this.$refs.formRules.validate();
			if (!success) return;

			this.loading = true;

			try {
				await api.call({
					path: `projects/${this.$route.params.id}/setup/${this.data.id}`,
					method: 'PUT',
					data: { ...this.formData }
				});

				this.loading = false;
			} catch (error) {
				console.log(error);
				this.loading = false;
			}

			// Hide the modal manually
			this.$nextTick(() => {
				// eslint-disable-next-line no-unused-expressions
				this.$refs.newSetupSettingsModal?.toggle();
			});

			this.$emit('done');
		}
	}
};
</script>

<style lang="scss" scoped></style>
