var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.new-setup-settings-modal",modifiers:{"new-setup-settings-modal":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Setup Settings'),expression:"'Setup Settings'",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon cursor-pointer",attrs:{"variant":"outline-secondary","size":"sm"},on:{"click":function($event){_vm.key++}}},[_c('feather-icon',{attrs:{"icon":"SettingsIcon","size":"16"}})],1),_c('b-modal',{ref:"newSetupSettingsModal",attrs:{"id":"new-setup-settings-modal","title":"Project setup settings","centered":""},on:{"hidden":_vm.resetModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
return [_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":cancel}},[_vm._v("Cancel")]),_c('loading-btn',{attrs:{"variant":"primary","loading":_vm.loading},on:{"click":_vm.handleOk}},[_vm._v("Save")])]}}])},[_c('validation-observer',{ref:"formRules"},[_c('b-form',{ref:"form"},[_c('b-form-group',{attrs:{"label":"City officer","label-for":"cityOfficer"}},[_c('validation-provider',{attrs:{"name":"City officer","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"cityOfficer","label":"name","filterable":false,"searchable":false,"options":_vm.cityOfficers,"reduce":function (o) { return o.id; },"state":errors.length > 0 ? false : null},model:{value:(_vm.formData.city_officer_id),callback:function ($$v) {_vm.$set(_vm.formData, "city_officer_id", $$v)},expression:"formData.city_officer_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Consultant","label-for":"consultant"}},[_c('validation-provider',{attrs:{"name":"Consultant","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"consultant","label":"name","filterable":false,"searchable":false,"options":_vm.consultants,"reduce":function (o) { return o.id; },"state":errors.length > 0 ? false : null},model:{value:(_vm.formData.consultant_id),callback:function ($$v) {_vm.$set(_vm.formData, "consultant_id", $$v)},expression:"formData.consultant_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Accountant","label-for":"accountant"}},[_c('validation-provider',{attrs:{"name":"Accountant","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"accountant","label":"name","filterable":false,"searchable":false,"options":_vm.accountants,"reduce":function (o) { return o.id; },"state":errors.length > 0 ? false : null},model:{value:(_vm.formData.accountant_id),callback:function ($$v) {_vm.$set(_vm.formData, "accountant_id", $$v)},expression:"formData.accountant_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Store keeper","label-for":"storeKeeper"}},[_c('validation-provider',{attrs:{"name":"Store keeper","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"storeKeeper","label":"name","filterable":false,"searchable":false,"options":_vm.storeKeepers,"reduce":function (o) { return o.id; },"state":errors.length > 0 ? false : null},model:{value:(_vm.formData.store_keeper_id),callback:function ($$v) {_vm.$set(_vm.formData, "store_keeper_id", $$v)},expression:"formData.store_keeper_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Bank account number","label-for":"banc-number"}},[_c('validation-provider',{attrs:{"name":"Bank account number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"banc-number","type":"number","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.bank_account_id),callback:function ($$v) {_vm.$set(_vm.formData, "bank_account_id", $$v)},expression:"formData.bank_account_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }